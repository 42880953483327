import React, { useState, useRef, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import Seo from "src/components/global/Seo.js";
import slugify from "slugify";
import Img from "gatsby-image";
import { Link } from "gatsby";

import Nav from "src/components/global/Nav.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";

import Project from "src/components/global/Projects/Project.js";
import ProjectGrid from "src/components/global/Projects/ProjectGrid.js";

import Title from "src/components/global/Typo/BigTitle.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";

import BigParagraph from "src/components/global/Typo/BigParagraph.js";
import Body from "src/components/global/Typo/Body.js";
import Line from "src/components/global/Line.js";

import main from "src/assets/styles/main.js";

import MainWrapper from "src/components/global/MainWrapper.js";
import typographySizes from "../assets/styles/typographySizes";
import Button from "src/components/global/Button.js";
import Highlighted from "src/components/works/Highlighted.js";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";
import CustomSelect from "src/components/Shop/CustomSelect.js";
import Footer from "src/components/global/Footer.js";
// markup

const Wrapper = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 15rem;
  }
`;

const StyledTitle = styled(Title)`
  position: relative;
  left: -1vw;
  margin-bottom: ${distances.gap * 3}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 10rem;
  }
`;
const TopWrapper = styled.div`
  padding-top: ${distances.topMargin}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top ${distances.gap * 2}rem;
  margin-bottom: ${distances.gap * 3}rem;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
flex-direction: column;
margin-top ${distances.gap}rem;
  }

`;

const ProductCol = styled.div`
  width: calc(33.33% - ${(distances.gap * 2) / 3}rem);
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    position: relative !important;
  }
`;

const ProductInfoWrapper = styled(ProductCol)`
  position: sticky;
  top: ${distances.gap * 6}rem;
  align-self: flex-start;
  height: calc(100vh - ${distances.gap * 7}rem);
  /* background: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    top: 0;
    height: initial;
  }
`;

const ProductOrderWrapper = styled(ProductCol)`
  position: sticky;
  top: ${distances.gap * 6}rem;
  align-self: flex-start;
  height: calc(100vh - ${distances.gap * 7}rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background: red; */
  overflow: scroll;
  @media (max-width: ${mediaQuery.tablet}) {
    top: 0;
    height: initial;
  }
`;

const ProducTitle = styled.h1`
  font-size: ${typographySizes.m}rem;
  line-height: ${typographySizes.mh};
  color: var(--rest);
  max-width: 310rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;
const ProductImgWrapper = styled(ProductCol)`
  ${ProducTitle} {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    order: -1;
    ${ProducTitle} {
      display: block;
      margin-bottom: ${distances.gap * 4}rem;
      font-size: ${typographySizes.sm}rem;
      line-height: ${typographySizes.smh};
      max-width: 210rem;
    }
  }
`;

const MobileOrderWraper = styled.div`
  position: absolute;
  width: 1rem;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  /* pointer-events: none; */
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
  }
`;

const MobileOrderBox = styled.div`
  height: 20.5rem;
  background: var(--rest);
  width: calc(100vw - 112rem - 55rem);
  border-radius: ${main.radius}rem;
  position: sticky;
  top: calc(100vh - 65.5rem);
  padding: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProductInfoBottomWrapper = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.gap}rem;
  }
`;

const ProducPrice = styled(SmallTitle)`
  span {
    display: inline-block;
    position: relative;
    font-size: ${typographySizes.sm}rem;
    line-height: ${typographySizes.smh};

    color: var(--rest);
    transform: translate(5rem, -7rem);
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.s}rem;
      line-height: ${typographySizes.sh};
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 10rem;
    display: none;
    &.mobile {
      display: block;
      color: var(--bg);
      margin-bottom: 0rem;
      margin-right: 5rem;
      span {
        color: var(--bg);
      }
    }
  }
`;
const ProductSubtitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};

  color: var(--rest);
  max-width: 300rem;
  margin-bottom: ${distances.gap}rem;
`;

const AditionalInformationWrapper = styled.div``;
const AditionalInformationPos = styled.div``;
const AditionalInformationTitle = styled.h5`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--rest);
  padding-top: ${distances.gap}rem;
`;

const AditionalInformationDescription = styled(Body)`
  padding-top: ${distances.gap}rem;
  padding-bottom: ${distances.gap}rem;
  width: 80%;
  max-width: 500rem;
`;

const ProductImg = styled(Img)`
  border-radius: ${main.radius}rem;
  margin-bottom: ${distances.gap}rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;

const ProductDescriptionWrapper = styled.div`
  border-radius: ${main.radius}rem;
  margin-bottom: ${distances.gap}rem;
  background: var(--rest);
  padding: ${distances.gap}rem;
  ${Body} {
    color: var(--bg);
  }
  @media (max-width: ${mediaQuery.desktop}) {
    padding: 15rem;
  }
`;

const ProductDescription = styled(Body)`
  margin-top: ${distances.gap * 6}rem;
  width: calc(100% - ${distances.gap * 4}rem);
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
  }
`;

const ProductTitle = styled.h5`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
`;

const OrderDescription = styled(Body)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.gap * 4}rem;
  }
`;

const ProductOrderBottomWrapper = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.gap * 4}rem;
  }
`;

const OrderForm = styled.div`
  width: 100%;
  /* margin-top: ${distances.gap * 2}rem; */
  /* background: red; */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLabel = styled.label`
  position: relative;
  color: var(--rest);
  font-size: ${typographySizes.s}rem !important;
  display: inline-block;
`;
const StyledSelect = styled.select`
 all: unset;
width: 100%;
padding: 10rem 0;
margin-top: ${distances.gap}rem;
outline: none;
color: var(--rest);
font-size: ${typographySizes.s}rem !important;
border-radius: 0;
-webkit-appearance: none;
background-color: transparent;
border-bottom: solid var(--rest); 1rem;
display: none;
@media (max-width: ${mediaQuery.tablet}) {
display: block;
}
`;

const StyledInput = styled.input`
 all: unset;
  width: 100%;
  padding: 10rem 0;
  margin-top: ${distances.gap}rem;
  outline: none;
  color: var(--rest);
  font-size: ${typographySizes.s}rem !important;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: transparent;
  border-bottom: solid var(--rest); 1rem;
  ::placeholder {
    all: unset;
    color: var(--rest);
  }
  ::autofill{
    background-color: transparent;
  }
  :valid{
    background-color: transparent;
  }`;

const Quantity = styled(StyledInput)`
  width: calc(50% - ${distances.gap / 2}rem);
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const OpptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .add {
    display: none;
  }
  :last-of-type {
    .add {
      display: block;
    }
  }
  :nth-of-type(5) {
    .add {
      display: none;
    }
  }
  :nth-of-type(1) {
    .remove.moreThanOne {
      display: none;
    }
  }
`;

const OpptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin-bottom: ${distances.gap}rem;
`;
const AddRemoveBtn = styled.div`
  height: 24rem;
  width: 24rem;
  border-radius: 24rem;
  border: solid 1rem var(--rest);
  position: relative;
  cursor: pointer;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10rem;
    height: 1rem;
    background: var(--rest);
  }

  span:first-of-type {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &.remove {
    span:first-of-type {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
`;
const SelectWrapper = styled.div`
  width: calc(50% - ${distances.gap / 2}rem);
`;

const CustomSelectWrapper = styled.div`
  display: block;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const ProductsContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;
const ButtonWrapper = styled.button`
  border: solid 1rem var(--rest);
  font-size: ${typographySizes.m}rem;
  text-align: center;
  color: var(--rest);
  align-self: center;
  cursor: pointer;
  /* margin-left: 50rem; */
  margin-top: ${distances.gap}rem;
  border-radius: 60rem;
  height: 51rem;
  overflow: hidden;
  padding: 0 40rem;
  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  @media (max-width: ${mediaQuery.tablet}) {
    /* font-size: ${typographySizes.s}rem;
    padding: 5rem 15rem;
    margin-left: 0rem;
    height: 30rem; */
    width: 100%;
    display: none;
    &.mobile {
      display: block;

      font-size: ${typographySizes.s}rem;
      padding: 5rem 15rem;
      margin: 0;
      height: 30rem;
      border: solid 1rem var(--bg);
      text-align: center;
      color: var(--bg);
      width: initial;
    }
  }
`;
const StyledCheckmark = styled.span`
  display: block;
  position: relative;
  width: 30rem;
  height: 30rem;
  vertical-align: middle;
  margin-right: 20rem;
  cursor: pointer;
  border: solid 1rem var(--rest);
  background-color: var(--bg);
  flex-shrink: 0;

  ::before {
    content: "";
    position: absolute;
    width: 0%;
    left: 50%;
    top: 50%;
    height: 6rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: var(--rest);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::after {
    content: "";
    position: absolute;
    width: 0%;
    left: 50%;
    top: 50%;
    height: 6rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: var(--rest);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const PersonaCheckmarkWrapper = styled.label`
  display: flex;
  margin-bottom: 20rem;
  font-size: ${typographySizes.ss}rem !important;
  liconstne-height: ${typographySizes.ssh};
  color: var(--rest);
  margin-top: 0rem;

  margin-top: 30rem;
  :first-of-type {
  }

  input {
    /* display: none; */
    position: absolute;
    border-radius: 0;
    /* -webkit-appearance: none; */
  }

  input:checked + ${StyledCheckmark} {
    ::after {
      width: 80%;
    }
    ::before {
      width: 80%;
    }
  }
`;
const TitleProducts = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
  opacity: 1;
`;

const Position = styled(Body)`
  margin: ${distances.gap}rem 0;

  /* @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  } */
  /* &.mobile {
    display: none;
    @media (max-width: ${mediaQuery.tablet}) {
      display: block;
      margin-top: 0;
    }
  } */
`;
const InWrapper = styled.div`
  overflow: hidden;
`;
const PersonImage = styled(Img)`
  border-radius: ${main.radius}rem;
  position: relative;
  height: 32vw;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    height: 60vw;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 130vw;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const PersonName = styled.h3`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--rest);
  margin: ${distances.gap}rem 0;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

const TopRowProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PersonWrapper = styled(Link)`
  width: calc(25% - ${(distances.gap * 3) / 4}rem);
  margin-right: ${distances.gap}rem;
  display: flex;
  flex-direction: column;
  margin-top: ${distances.gap * 3}rem;
  &:nth-child(4n) {
    margin-right: 0rem;
  }
  &:nth-child(-n + 4) {
    margin-top: 0rem;
  }
  .hoverImg {
    display: none;
  }
  :hover {
    ${PersonImage} {
      display: none;
    }
    .hoverImg {
      display: block;
    }
  }
  /* :hover {
    ${InfoWrapper} {
      transform: translateY(-35vw);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${PersonImage} {
      transform: translateY(-35vw);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    @media (max-width: ${mediaQuery.laptop}) {
      ${InfoWrapper} {
        transform: translateY(-60vw);
      }
      ${PersonImage} {
        transform: translateY(-60vw);
      }
    }
    @media (max-width: ${mediaQuery.tablet}) {
      ${InfoWrapper} {
        transform: translateY(0);
      }
      ${PersonImage} {
        transform: translateY(0);
      }
    }
  } */
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - ${distances.gap / 2}rem);
    &:nth-child(4n) {
      margin-right: ${distances.gap}rem;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(-n + 4) {
      margin-top: ${distances.gap * 3}rem;
    }
    &:nth-child(-n + 2) {
      margin-top: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;

    margin-right: 0;

    &:nth-child(-n + 2) {
      margin-top: ${distances.gap * 3}rem;
    }
    &:first-of-type {
      margin-top: 0;
    }
    /* hr:first-of-type {
      display: none;
    } */
  }
`;

const Shop = ({ data, pageContext }) => {
  const [optionRow, setOptionRow] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const addRow = () => {
    const updatedOptionRow = [...optionRow]; // Create a copy of the optionRow array

    // Find the index of the first occurrence of false
    const index = updatedOptionRow.findIndex((value) => value === false);

    if (index !== -1) {
      // If a false value is found, change it to true
      updatedOptionRow[index] = true;
      setOptionRow(updatedOptionRow); // Update the state with the modified array
    }
  };

  const removeRow = (index) => {
    const updatedOptionRow = [...optionRow]; // Create a copy of the optionRow array};
    updatedOptionRow[index] = false; // Change the element at the specified index to false
    setOptionRow(updatedOptionRow); // Update the state with the modified array
  };

  const [isVisible, setIsVisible] = useState(false);
  const formRef = useRef(null);
  const infoformRef = useRef(null);
  const formRefSubimt = useRef(null);

  const isBrowser = typeof window !== "undefined";
  const mobile = isBrowser && window.innerWidth < 768;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.8 } // Adjust the threshold value as needed
    );

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  const handleSubmit = (e) => {
    !isVisible && e.preventDefault();
    infoformRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper>
      <Seo
        title={`we3studio : ${data.strapiShopProduct.Title}`}
        description={data.strapiShopProduct.Description}
        image={data.strapiShopProduct.Media[0].localFile.publicURL}
      />

      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        catalog={`${pageContext.catalog}/`}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
        backBtnUrl
        hideCta
      />

      <MainWrapper>
        <TopWrapper>
          <StyledTitle>
            <Animate text={data.strapiPageShop.Title} />
          </StyledTitle>
        </TopWrapper>
        <Line />
        <form
          ref={formRefSubimt}
          action="https://submit-form.com/wVVuggoV"
          id="form"
        >
          <ProductWrapper>
            <ProductInfoWrapper>
              <ProducTitle>{data.strapiShopProduct.Title}</ProducTitle>
              <ProductInfoBottomWrapper>
                <ProducPrice>
                  {data.strapiShopProduct.Price}
                  <span>PLN</span>{" "}
                </ProducPrice>
                <ProductSubtitle>
                  {data.strapiShopProduct.Subtitle}
                </ProductSubtitle>
                <AditionalInformationWrapper>
                  {data.strapiShopProduct.Aditional_information.map((pos) => (
                    <AditionalInformationPos>
                      <Line />
                      <AditionalInformationTitle>
                        {pos.Title}
                      </AditionalInformationTitle>
                      <AditionalInformationDescription>
                        {pos.Description}
                      </AditionalInformationDescription>
                    </AditionalInformationPos>
                  ))}
                  <Line />
                </AditionalInformationWrapper>
              </ProductInfoBottomWrapper>
            </ProductInfoWrapper>
            <ProductImgWrapper>
              <ProducTitle>{data.strapiShopProduct.Title}</ProducTitle>

              {data.strapiShopProduct.Media.map((pos, index) => (
                <>
                  <ProductImg
                    loading="eager"
                    fluid={pos.localFile.childImageSharp.fluid}
                  />
                  {index === 0 && (
                    <ProductDescriptionWrapper>
                      <ProductTitle>
                        {pageContext.locale == "pl"
                          ? "Opis produktu"
                          : "Product description"}
                      </ProductTitle>
                      <ProductDescription>
                        {data.strapiShopProduct.Description}
                      </ProductDescription>
                    </ProductDescriptionWrapper>
                  )}
                </>
              ))}
            </ProductImgWrapper>
            <ProductOrderWrapper>
              <OrderDescription ref={infoformRef}>
                {data.strapiPageShop.Product_Page.Order_info}
              </OrderDescription>
              <OrderForm ref={formRef}>
                <div>
                  <input type="hidden" name="_feedback.dark" value="true" />
                  <input
                    type="hidden"
                    name="produkt"
                    value={data.strapiShopProduct.Title}
                  />
                  <StyledInput
                    type="text"
                    id="Name"
                    name="Name"
                    required
                    placeholder="name"
                  ></StyledInput>
                  <StyledInput
                    type="text"
                    id="email"
                    name="email"
                    required
                    placeholder="e-mail"
                  ></StyledInput>
                  {optionRow.map((pos, index) => (
                    <>
                      {optionRow[index] == true && (
                        <OpptionsContainer>
                          <OpptionsWrapper>
                            {data.strapiShopProduct.Options.map((pos) => (
                              <SelectWrapper>
                                <>
                                  {mobile ? (
                                    <StyledSelect
                                      id={pos.Name}
                                      name={pos.Name}
                                      form="form"
                                      required
                                    >
                                      <option value="" disabled selected hidden>
                                        {pos.Name}
                                      </option>
                                      {pos.Options.map((posEl) => (
                                        <option value={posEl.Name}>
                                          {posEl.Name}
                                        </option>
                                      ))}
                                    </StyledSelect>
                                  ) : (
                                    <CustomSelect
                                      name={pos.Name}
                                      options={[
                                        pos.Options.map((posEl) => {
                                          return {
                                            value: `${posEl.Name}`,
                                            label: `${posEl.Name}`,
                                          };
                                        }),
                                      ]}
                                    />
                                  )}
                                </>
                              </SelectWrapper>
                            ))}
                            <Quantity
                              type="number"
                              id="ilość"
                              name="ilość"
                              required
                              min="1"
                              max="10"
                              placeholder={
                                pageContext.locale == "pl"
                                  ? "ilość"
                                  : "quantity"
                              }
                            ></Quantity>
                          </OpptionsWrapper>
                          <AddRemoveBtn
                            className={`remove ${
                              optionRow.filter((element) => element === true)
                                .length === 1 && "moreThanOne"
                            }`}
                            onClick={() => removeRow(index)}
                          >
                            {" "}
                            <span></span>
                            <span></span>{" "}
                          </AddRemoveBtn>
                          <AddRemoveBtn onClick={addRow} className="add">
                            {" "}
                            <span></span>
                            <span></span>
                          </AddRemoveBtn>
                          {/* <Line /> */}
                        </OpptionsContainer>
                      )}
                    </>
                  ))}
                </div>
                <ProductOrderBottomWrapper>
                  <PersonaCheckmarkWrapper>
                    <input name="Gdpr" type="checkbox" required />
                    <StyledCheckmark />
                    {pageContext.locale === "pl"
                      ? "Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z realizacją zgłoszenia. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem /am poinformowany /a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest we3studio s.c. ul. wilcza 2/4, m16, 00-532 Warszawa."
                      : `I consent to the processing of my personal data in accordance with the Law on Personal Data Protection in connection with the execution of the request. Provision of data is voluntary, but necessary to process the request. I have been informed that I have the right to access my data, the possibility of correcting them, demand to stop processing them. The administrator of the personal data is we3studio s.c. 2/4 wilcza street, m16, 00-532 Warsaw. `}
                  </PersonaCheckmarkWrapper>
                  <ButtonWrapper type="submit">
                    <span>
                      {pageContext.locale === "pl" ? "zamów" : `order `}
                    </span>
                    <span>
                      {pageContext.locale === "pl" ? "zamów" : `order `}
                    </span>
                  </ButtonWrapper>
                </ProductOrderBottomWrapper>
              </OrderForm>
            </ProductOrderWrapper>
            <MobileOrderWraper>
              <MobileOrderBox>
                {" "}
                <ButtonWrapper onClick={handleSubmit} className="mobile">
                  <span>
                    {pageContext.locale === "pl" ? "zamów" : `order `}
                  </span>
                  <span>
                    {pageContext.locale === "pl" ? "zamów" : `order `}
                  </span>
                </ButtonWrapper>
                <ProducPrice className="mobile">
                  {data.strapiShopProduct.Price}
                  <span>PLN</span>{" "}
                </ProducPrice>
              </MobileOrderBox>
            </MobileOrderWraper>
          </ProductWrapper>
        </form>
        {data.allStrapiShopProduct.edges.length > 0 && (
          <TitleProducts>
            {data.strapiPageShop.All_products_title}
          </TitleProducts>
        )}

        <ProductsContainer>
          {data.allStrapiShopProduct.edges.map((pos) => {
            const slugifyedTitle = slugify(pos.node.Title, {
              lower: true,
              strict: true,
            });

            // const url =
            //   pageContext.locale === "pl"
            //     ? "sklepik"
            //     : `${pageContext.locale}/shop`;
            return (
              <PersonWrapper
                to={`/${
                  pageContext.locale == "pl" ? "sklepik" : "en/shop"
                }/${slugifyedTitle}`}
              >
                <Line />
                <TopRowProductWrapper>
                  <Position>{pos.node.Type_of_product}</Position>
                  <Position>{pos.node.Price} PLN</Position>
                </TopRowProductWrapper>

                <InWrapper>
                  <PersonImage
                    loading="eager"
                    fluid={pos.node.Media[0].localFile.childImageSharp.fluid}
                  />
                  <PersonImage
                    className="hoverImg"
                    loading="eager"
                    fluid={
                      pos.node.Media[pos.node.Media[1] ? 1 : 0].localFile
                        .childImageSharp.fluid
                    }
                  />
                  {/* {pos.node.Media} */}
                  <InfoWrapper>
                    <PersonName>{pos.node.Title} </PersonName>
                    {/* <Position className="mobile">{position}</Position> */}
                    {/* <Description>{info}</Description> */}
                  </InfoWrapper>
                </InWrapper>
                <Line />
              </PersonWrapper>
            );
          })}
        </ProductsContainer>
      </MainWrapper>

      <Footer
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        lang={pageContext.locale}
      />
    </Wrapper>
  );
};

export default Shop;

export const IndexQuery = graphql`
  query ShopProductPage($locale: String!, $id: String!) {
    strapiPageShop(locale: { eq: $locale }) {
      Description
      Title
      All_products_title
      Page_name
      Product_Page {
        Order_info
      }
    }
    strapiShopProduct(locale: { eq: $locale }, id: { eq: $id }) {
      Price
      Title
      Description
      Type_of_product
      Subtitle
      Options {
        Options {
          Name
        }
        Name
      }
      Aditional_information {
        Description
        Title
      }
      Media {
        localFile {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 508
              quality: 100
              webpQuality: 100
              jpegQuality: 100
              pngQuality: 100
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiShopProduct(filter: { locale: { eq: $locale }, id: { ne: $id } }) {
      edges {
        node {
          Price
          Title
          Type_of_product
          Media {
            localFile {
              publicURL
              childImageSharp {
                fluid(
                  maxWidth: 508
                  quality: 100
                  webpQuality: 100
                  jpegQuality: 100
                  pngQuality: 100
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    strapiGlobal(locale: { eq: $locale }) {
      Project_button
    }

    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }

    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
