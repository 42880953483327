import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";
import distances from "src/assets/styles/distances.js";

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  #arrow {
    fill: var(--rest);
  }
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledArrow />
    </components.DropdownIndicator>
  );
};

const CustomSelect = (props) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: `1rem solid var(--rest)`,
      color: state.isSelected ? `var(--bg)` : ` var(--rest)`,
      backgroundColor: state.isSelected ? `var(--rest)` : ` var(--bg)`,
      cursor: "pointer",

      fontSize: typographySizes.s,
    }),
    control: (provided, state) => {
      // const height = "60rem";
      const width = "100%";
      const backgroundColor = "var(--bg)";

      const borderRadius = "none";
      const border = "none";
      const marginBottom = "0rem";
      const borderBottom = `solid 1rem var(--rest)`;
      const cursor = "pointer";
      const marginTop = "20rem";
      // const display = "block";
      return {
        ...provided,
        // height,
        backgroundColor,

        borderRadius,
        border,
        marginBottom,
        cursor,
        width,
        borderBottom,
        marginTop,
        // width,
        // display,
      };
    },
    menuList: (provided, state) => {
      const backgroundColor = "var(--rest)";
      const color = "var(--rest)";
      const borderRadius = "none";
      const border = "none";
      const padding = "0";

      return {
        ...provided,
        padding,
        backgroundColor,
        borderRadius,
        border,
      };
    },
    dropdownIndicator: (provided, state) => {
      const paddingLeft = "0rem";

      return { ...provided, paddingLeft };
    },
    indicatorSeparator: (provided, state) => {
      const display = "none";

      return { ...provided, display };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const fontSize = typographySizes.s;
      const color = "var(--rest)";

      return {
        ...provided,
        opacity,
        transition,
        fontSize,
        color,
      };
    },
    valueContainer: (provided, state) => {
      const padding = "0";
      const fontSize = typographySizes.s;
      const color = "var(--rest)";

      return {
        ...provided,
        padding,
        fontSize,
        color,
      };
    },
  };
  return (
    <Select
      isSearchable={false}
      name={props.name}
      components={{ DropdownIndicator }}
      options={props.options[0]}
      styles={customStyles}
      placeholder={props.name}
      // defaultValue={props.options[0][0]}
      onChange={props.onChange}
    />
  );
};

export default CustomSelect;
